@charset "UTF-8";
.el-dialog__body,
.el-cascader-panel {
  font-size: 12px;
  font-weight: normal; }

.el-input,
.el-input--small {
  font-size: 13px; }

.el-form-item__content,
.el-select-dropdown__item {
  font-size: 12px; }

.el-tabs__item.is-active,
.el-loading-spinner .el-loading-text,
.el-button--text {
  color: #006eff; }

.el-button--text {
  font-weight: normal; }

.el-tabs__active-bar {
  background-color: #006eff; }

.el-loading-spinner .path {
  stroke: #006eff; }

thead .el-button--text {
  color: inherit;
  font-weight: bold; }

.el-textarea__inner,
.el-button {
  border-radius: 0 !important; }

.el-button--primary {
  background-color: #006eff !important;
  border-color: #006eff !important; }

.el-message-box {
  padding: 20px 5px;
  border-radius: 0px;
  width: 460px; }
  .el-message-box .el-message-box__header {
    padding-top: 0; }
    .el-message-box .el-message-box__header .el-message-box__headerbtn {
      top: 0; }
  .el-message-box .el-message-box__title {
    font-size: 16px;
    color: #000;
    font-weight: bold; }
  .el-message-box .el-message-box__content {
    font-size: 12px;
    color: #444; }
  .el-message-box .el-message-box__btns {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center; }
    .el-message-box .el-message-box__btns .el-button {
      margin: 0 5px;
      color: #606266; }
    .el-message-box .el-message-box__btns .el-button--primary {
      color: #fff;
      background-color: #006eff;
      border-color: #006eff; }

.el-link.el-link--primary {
  color: #006eff; }

.el-radio-button__inner {
  border-radius: 0 !important; }

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #006eff;
  border-color: #006eff; }

.el-dialog__title,
.el-dialog__close {
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 16px;
  font-weight: 600; }

.el-dialog__close {
  color: #888888 !important; }

.el-dialog__footer {
  text-align: center; }

.el-cascader-menu__list {
  min-height: 46px; }

.el-input-number--small {
  line-height: 28px; }
  .el-input-number--small .el-input--small {
    line-height: inherit; }

.dialog-body .el-input__inner {
  width: 100%; }

.dialog-body .el-input-number {
  width: 100%;
  max-width: 300px; }

.form-item__tooltip_icon {
  color: #999; }

.el-checkbox {
  color: #444; }

.el-checkbox__input.is-disabled + span.el-checkbox__label {
  color: #888;
  cursor: not-allowed; }

.el-checkbox__label {
  font-size: 12px; }

.el-table__header .is-required .cell::after {
  content: '*';
  color: #f56c6c;
  margin-left: 2px; }

.table-card {
  background: #fff;
  box-shadow: 0px 2px 4px rgba(54, 58, 80, 0.32); }
  .table-card .el-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px 16px 0; }
    .table-card .el-pagination .el-pagination__sizes {
      margin: 0 10px 0 0;
      font-weight: 400;
      color: #606266; }
      .table-card .el-pagination .el-pagination__sizes .el-select .el-input {
        margin: 0 20px 0 0; }
    .table-card .el-pagination .el-pagination__total {
      position: absolute;
      left: 40px; }
  .table-card .is-background .el-pager li:not(.disabled).active {
    background-color: #006eff; }

.el-input__suffix-inner .el-icon-arrow-up:before,
.el-cascader-node .el-icon-arrow-up:before {
  content: ''; }

.el-input__suffix-inner .el-icon-arrow-down:before,
.el-cascader-node .el-icon-arrow-down:before {
  content: ''; }

.el-input__suffix-inner .el-icon-arrow-right:before,
.el-cascader-node .el-icon-arrow-right:before {
  content: ''; }

.el-input__suffix-inner .el-icon-arrow-left:before,
.el-cascader-node .el-icon-arrow-left:before {
  content: ''; }

.el-dropdown .el-icon--right {
  margin-left: 8px; }

.el-dropdown .el-icon-arrow-down:before {
  content: ''; }

.el-dropdown .el-dropdown-menu .el-dropdown-menu__item {
  color: #000;
  font-size: 12px; }

.el-form-item__content {
  margin-right: 20px; }
  .el-form-item__content .el-button {
    margin-right: 8px;
    margin-left: 0px; }

.el-input__inner {
  border-radius: 0 !important; }

.el-input--prefix .el-input__inner {
  padding-right: 30px; }

.el-input__prefix {
  right: 5px; }

.el-select .el-input__inner:focus {
  border-color: #006eff; }

.el-select-dropdown__item.selected {
  color: #006eff; }

.el-input__inner {
  color: rgba(0, 0, 0, 0.9); }

.black-hovers {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 40; }

.el-table {
  color: #000000; }
  .el-table td td.el-table__cell div {
    display: flex;
    align-items: center; }
  .el-table td div .el-divider:nth-last-child(2) {
    margin-right: 0px; }

body {
  background: #f3f4f7; }
  body .t-container {
    padding: 20px; }
    body .t-container .data-list__head {
      padding: 0;
      background-color: transparent; }
      body .t-container .data-list__head .el-form {
        display: flex;
        align-items: center;
        flex-wrap: wrap; }
  body .main_tile {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 0px #cfd5de;
    height: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 60px;
    color: #000000;
    padding: 0 20px;
    box-shadow: 0px 1px 0px #cfd5de; }
