
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
