@charset "UTF-8";
div,
img,
a,
button,
input,
select,
textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none; }

html {
  font-size: 26.67vw; }

body {
  font-family: Calibri, Arial, 'Microsoft Yahei', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-text-size-adjust: none; }

html,
body {
  width: 100%;
  height: 100%; }

/* 表格列表页公共样式 */
.table-container {
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 20px 30px; }

.no-permission-message .el-message-box__headerbtn {
  display: none; }

@media screen and (max-width: 500px) {
  .no-permission-message {
    width: 350px; }
  img {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }
  .el-message {
    min-width: 300px !important; } }

.danger {
  color: #ff0000 !important; }
  .danger:focus, .danger:hover {
    opacity: 0.8;
    color: #ff0000 !important; }

/* 工资条详情样式，因多处使用，故放在公共样式 */
.detail-item-con {
  background: #fff;
  border-radius: 0.06rem;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.08rem; }
  .detail-item-con h3.title {
    border-left: 0.05rem solid #0643b6;
    padding-left: 0.05rem;
    padding-top: 0.05rem;
    padding-bottom: 0.05rem;
    margin-top: 0.04rem;
    margin-left: 0.04rem;
    margin-bottom: 0.05rem;
    display: flex;
    align-items: center; }
    .detail-item-con h3.title span {
      flex: 1;
      margin-left: 0.08rem;
      display: block;
      font-size: 0.15rem;
      font-weight: bold;
      line-height: 1.1;
      position: relative; }
      .detail-item-con h3.title span:after {
        content: '';
        position: absolute;
        top: 52%;
        background: #eee;
        width: 77%;
        height: 1px; }
      .detail-item-con h3.title span:after {
        right: 0; }
  .detail-item-con .box {
    font-size: 0.15rem;
    line-height: 0.44rem;
    padding-left: 0.15rem; }
    .detail-item-con .box:last-child .detail-item {
      border-bottom: none; }

@media screen and (min-width: 768px) {
  .detail-item-con {
    border-radius: 8px;
    margin-bottom: 8px; }
    .detail-item-con h3.title {
      border-left-width: 5px;
      padding-left: 5px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-top: 4px;
      margin-left: 4px;
      margin-bottom: 5px; }
      .detail-item-con h3.title .svg-icon {
        font-size: 18px !important; }
      .detail-item-con h3.title span {
        font-size: 16px;
        margin-left: 8px; }
    .detail-item-con .box {
      font-size: 16px;
      line-height: 44px;
      padding-left: 15px; } }
